import React from "react";
import { Row, Col } from "react-bootstrap";
import imgI1 from "../../assets/image/svg/risk-profiling.svg";
import imgI2 from "../../assets/image/svg/test.svg";
import imgI3 from "../../assets/image/svg/credit-dashboard.svg";
import imgHero from "../../assets/extra/Products/credit-scoring.svg";

const Caas = () => {
  return (
    <Row
      className="align-items-center justify-content-center mb-18 mt-10 rounded-10 pt-8 pb-8 pl-7"
      id="caas"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="500"
    >
      <Col sm="10" lg="6" className="offset-xl-1  order-lg-1 mb-9 mb-lg-0">
        <div
          className="double-image-group-opacity md-10"
          data-aos="zoom-in"
          data-aos-duration="500"
          data-aos-delay="500"
        >
          <img
            className="main-img  zoom-product-3 mb-12 landing-large"
            src={imgHero}
            alt="main image"
          />
        </div>
      </Col>
      <Col sm="12" lg="5" md="10" className="order-lg-2  ezloan-products">
        <div
          className="content-text"
          data-aos="fade-right"
          data-aos-duration="800"
          data-aos-delay="500"
        >
          <h2
            className="gr-text-4 mb-7 justify-content-center"
            style={{ color: "rgb(74, 99, 165)" }}
          >
            Credit scoring-as-a-Service{" "}
          </h2>
          <p className="gr-text-9 mb-0 s " style={{ textAlign: "left" }}>
            Score your loan applicants effortlessly via our credit scoring API
            which returns a complete scorecard. Customize credit scoring models
            to your own risk profile. Employ traditional or alternative data for
            scoring.
          </p>
          <Row
            className=" mt-8"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
            style={{ textAlign: "left" }}
          >
            <Col
              md="6"
              lg="6"
              sm="12"
              xs="12"
              className="mb-3 mr-5 ezloan-products-text"
            >
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-3 mb-md-0 center">
                  <img className="" src={imgI1} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6 limit-2">
                    Tailored Risk Profile
                  </h3>
                </div>
              </div>
            </Col>
            <Col
              md="5"
              lg="5"
              sm="12"
              xs="12"
              className="mb-3 ml-0 mr-5 ezloan-products-text"
            >
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-3 mb-md-0">
                  <img className="" src={imgI3} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6">
                    Credit Scorecard Dashboard{" "}
                  </h3>
                </div>
              </div>
            </Col>
          </Row>

          <Row
            className=" "
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
          >
            <Col
              md="5"
              lg="8"
              sm="12"
              xs="12"
              className="mb-3 ml-0 mr-5 ezloan-products-text-3"
            >
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-3 mb-md-0">
                  <img className="" src={imgI2} alt="feature" />
                </div>
                <p className="content">
                  <h3 className="title gr-text-7 mb-6">
                    Digital Footprint Credit Scoring
                  </h3>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default Caas;
