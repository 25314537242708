import React from "react";
import Seo from "../components/Seo.js";
import EzloanUseCases from "../components/EzloanUseCases";
import ValuePropEzloan from "../components/ValuePropEzloan";

import PageWrapper from "../components/PageWrapper";
// import Hero from "../sections/landing8/Hero";
import Hero from "../sections/landing6/EzloanHero";

// import Feature from "../sections/landing1/Feature";
import EzloanFeatures from "../sections/landing1/EzloanFeatures";

import { Row, Col, Container } from "react-bootstrap";

import "../addedcss/styles.scss";

import ogImage from "../assets/extra/global/link-logo-white.png";
import EzloanDiff from "../components/EzloanDiff";
import EzloanBrands from "../components/EzloanBrands.js";

const IndexPage = () => {
  // const [buttonState, setButtonState] = useState('disabled')

  // let onVerification = () => setButtonState('enabled')

  const imgurl = `https://genify.ai${ogImage}`;

  return (
    <>
      <Seo
        title="EZloan | Genify"
        image={imgurl}
        description="Loan marketplace for the unbanked"
      />

      <PageWrapper
        headerConfig={{
          theme: "dark",
          align: "right",
          variant: "primary",
          isFluid: true,
          button: "null", //cta, account, null
          source: "ezloan",
        }}
        footerConfig={{
          style: "ezloan", //style1, style2
        }}
      >
        <Hero />
        <ValuePropEzloan />
        <hr
          style={{ margin: "0", padding: "1", borderTop: "1px solid #828dbf" }}
        ></hr>
        <div className="inner-banner pt-29 pb-md-2 bg-default-1">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2
                    className="title gr-text-2 mb-9"
                    style={{ color: "rgb(74, 99, 165)" }}
                  >
                    Products
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <EzloanFeatures />

        <div
          className="inner-banner pt-29  bg-default-1 pb-25 border-top-5"
          style={{ backgroundColor: "rgb(74, 99, 165)" }}
        >
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2
                    className="title gr-text-2 mb-9"
                    style={{ color: "#fff" }}
                  >
                    Why EZloan?
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
          <EzloanDiff />
        </div>

        <div className="inner-banner pt-29 pb-md-3 bg-default-1">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9" style={{}}>
                    Showcase
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <EzloanUseCases />
        <div className="inner-banner pt-10 pb-md-3 bg-default-1">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9" style={{}}>
                    Partners
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <EzloanBrands />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
