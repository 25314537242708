import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import egbank from "../assets/image/svg/egbank.svg";
import fintech from "../assets/image/png/fintech.png";
import mint from "../assets/image/png/mint.png";
import aucvl from "../assets/image/png/aucvl.png";

const EzloanBrands = () => {
  return (
    <>
      <div className="brand-section pt-7 pb-25  ">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs="12">
              <div className="brand-logos d-flex justify-content-center justify-content-xl-between align-items-center mx-n9 flex-wrap ">
                <div className="single-brand mx-9 py-6 gr-opacity-8 brand-logo">
                  <img
                    src={egbank}
                    alt="eg bank logo"
                    className="w-100 ml-10"
                    style={{ minWidth: "15vw", minHeight: "13vh" }}
                  />
                </div>
                <div className="single-brand mx-9 py-6 gr-opacity-8 brand-logo">
                  <img
                    src={fintech}
                    alt="fintech logo"
                    className="w-100 "
                    // style={{ maxWidth: '15vw', maxHeight: '16vh' }}
                  />
                </div>
                <div className="single-brand mx-9 py-6 gr-opacity-8 brand-logo">
                  <img
                    src={mint}
                    alt="mint logo"
                    className="w-100 "
                    style={{ minWidth: "9vw", minHeight: "13vh" }}
                  />
                </div>
                <div className="single-brand mx-9 py-6 gr-opacity-8 brand-logo">
                  <img
                    src={aucvl}
                    alt="auc virtual lab logo"
                    className="w-100 "
                    style={{ minWidth: "9vw", minHeight: "13vh" }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EzloanBrands;
