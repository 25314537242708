import React from "react";
import { Row, Col } from "react-bootstrap";
// import imgHero from '../../assets/image/svg/Dashboard.svg'
// import imgHero from '../../assets/image/png/dashboard.png'
import imgHero from "../../assets/image/svg/landing.svg";

import imgI1 from "../../assets/image/svg/score-card.svg";
import imgI2 from "../../assets/image/svg/e-kyc-manage.svg";
import imgI3 from "../../assets/image/svg/loan-collection.svg";
import imgI4 from "../../assets/image/svg/service-dashboard.svg";
import imgI5 from "../../assets/image/svg/lead-generation.svg";

const LendingMarketplace = () => {
  return (
    <Row
      className="align-items-center justify-content-center mb-18 mt-10 rounded-10 pt-8 pb-8 pl-7 "
      id="lending-marketplace"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="500"
    >
      <Col sm="10" lg="5" className=" order-lg-2  mb-lg-0 ">
        <div
          className="double-image-group-opacity md-10"
          data-aos="zoom-in"
          data-aos-duration="500"
          data-aos-delay="500"
        >
          <img className="zoom-product-4" src={imgHero} alt="image hero" />
        </div>
      </Col>
      <Col sm="10" md="10" lg="6" className="order-lg-1 ezloan-products">
        <div
          className="content-text"
          data-aos="fade-right"
          data-aos-duration="800"
          data-aos-delay="500"
        >
          <h2
            className="gr-text-4 mb-7 justify-content-center"
            style={{ color: "rgb(74, 99, 165)" }}
          >
            Lending Marketplace{" "}
          </h2>
          <p className="gr-text-9 mb-0 s " style={{ textAlign: "left" }}>
            Easily access, evaluate, and approve loan applications sent to you
            from our lending marketplace. Tailor to your specific risk tolerance
            and use our digital credit scoring engine to filter applicants.
            Service loan end to end via dashboards on our portal.
          </p>
          <Row
            className=" mt-8"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
            style={{ textAlign: "left" }}
          >
            <Col
              md="5"
              lg="5"
              sm="9"
              xs="12"
              className="mb-3 ml-0 mr-5 ezloan-products-text"
            >
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-3 mb-md-0">
                  <img className="" src={imgI5} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6">
                    Borrower Lead Generation
                  </h3>
                </div>
              </div>
            </Col>
            <Col
              md="6"
              lg="6"
              sm="9"
              xs="12"
              className="mb-3 mr-5 ezloan-products-text"
            >
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-3 mb-md-0">
                  <img className="" src={imgI1} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6">
                    Digital Credit Scorecard
                  </h3>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            className=" "
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
          >
            <Col
              md="5"
              lg="5"
              sm="9"
              xs="12"
              className="mb-3 ml-0 mr-5 ezloan-products-text"
            >
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-3 mb-md-0">
                  <img className="" src={imgI4} alt="feature" />
                </div>
                <div className="content">
                  <div className="content">
                    <h3 className="title gr-text-7 mb-6">
                      Loan Portfolio Servicing Dashboard{" "}
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              md="5"
              lg="6"
              sm="6"
              xs="12"
              className="mb-12 ezloan-products-text"
            >
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI3} alt="feature" />
                </div>
                <div className="content">
                  <h3 className="title gr-text-7 mb-6">
                    Loan Disbursement and Collection
                  </h3>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            className=" "
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="500"
          >
            <Col
              md="4"
              lg="4"
              sm="6"
              xs="12"
              className="mb-12 ml-0 mr-15 ezloan-products-text"
            >
              <div className="feature-widget d-sm-flex">
                <div className="widget-icon gr-text-4 text-blue mr-8 mb-7 mb-md-0">
                  <img className="" src={imgI2} alt="feature" />
                </div>
                <div className="content">
                  <div className="content">
                    <h3 className="title gr-text-7 mb-6">e-KYC Management</h3>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Link
						to='txn-categorizer'
						className='btn-link with-icon gr-text-blue gr-text-7 font-weight-bold mt-9'
						data-aos='fade-up'
						data-aos-duration='800'
						data-aos-delay='500'
						style={{ color: '#ff565b' }}>
						More Info <i className='icon icon-tail-right font-weight-bold'></i>
					</Link> */}
        </div>
      </Col>
    </Row>
  );
};

export default LendingMarketplace;
