import React from "react";
import { Row, Col } from "react-bootstrap";

const ValuePropEzloan = () => {
  return (
    <Row
      className="justify-content-lg-between justify-content-md-between justify-content-sm-between align-items-center  test-value"
      style={{
        backgroundColor: "#4161a6",
        textAlign: "left",
        paddingLeft: "2rem",
        paddingTop: "2rem",
        marginTop: "2rem",
      }}
    >
      {/* 'linear-gradient(#4161a6, #828dbf)' */}
      <Col lg="6" xl="5" sm="12" xs="12" className="value-prop-m">
        <div className="section-title mb-7  mb-lg-16 pr-11 dark-mode-texts">
          {/* <img className="img-fluid" src={imgI1} alt="" /> */}

          <h2
            className="title gr-text-4 mb-7 "
            style={{ color: "#fff", fontWeight: "700" }}
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            Offer loans to an untapped market
          </h2>
          <p
            className="gr-text-9 mb-0"
            style={{ color: "#fff", fontWeight: "200" }}
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="800"
          >
            Generate previously unscorable leads. Combine alternative data
            sources, machine learning and tailored risk profiles. Manage user
            loan applications and service loans end to end from our all-in-one
            portal.
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default ValuePropEzloan;
