import React from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/modules/effect-cube/effect-cube.min.css";
import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const EzloanDiff = () => {
  return (
    <div
      className="differentiation"
      style={{ margin: "auto", textAlign: "center", maxWidth: "40vw" }}
    >
      <Swiper
        className="differentiation"
        spaceBetween={20}
        slidesPerView={1}
        loop
        autoplay={{ delay: 4000 }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        // onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide
          style={{ boxShadow: "0 34px 33px -23px rgba(22, 28, 45, 0.13)" }}
        >
          <div
            className="feature-widget focus-reset  d-flex flex-column min-height-px-250 max-height-px-250 border rounded-10 pt-8 pb-8  px-7 mx-6 pl-lg-9 pr-md-8 pr-lg-10 gr-hover-shadow-1 differ-md-sm"
            style={{ backgroundColor: "#ff565b" }}
          >
            <h4
              className="pre-title gr-text-12   text-uppercase mb-7"
              style={{
                textAlign: "center",
                color: "#ffffff",
                fontSize: "2.1rem",
                fontWeight: "700",
              }}
            >
              Dynamic Credit Scoring
            </h4>{" "}
            <p className="gr-text-9" style={{ color: "#fff" }}>
              Our machine learning models improve over time.
            </p>
            <br />
          </div>
        </SwiperSlide>

        <SwiperSlide
          style={{ boxShadow: "0 34px 33px -23px rgba(22, 28, 45, 0.13)" }}
        >
          <div
            className="feature-widget focus-reset  d-flex flex-column min-height-px-346 max-height-px-346 border rounded-10 pt-8 pb-8  px-7 mx-6 pl-lg-9 pr-md-8 pr-lg-10 gr-hover-shadow-1 differ-md-sm"
            style={{ backgroundColor: "#ff565b" }}
          >
            <h4
              className="pre-title gr-text-12   text-uppercase mb-7"
              style={{
                textAlign: "center",
                color: "#ffffff",
                fontSize: "2.1rem",
                fontWeight: "700",
              }}
            >
              Data Security{" "}
            </h4>{" "}
            <p className="gr-text-9" style={{ color: "#fff" }}>
              {" "}
              Our servers are ISO 27001 certified and we are GDPR compliant—no
              data goes shared without user consent.
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide
          style={{ boxShadow: "0 34px 33px -23px rgba(22, 28, 45, 0.13)" }}
        >
          <div
            className="feature-widget focus-reset  d-flex flex-column min-height-px-346 max-height-px-346 border rounded-10 pt-8 pb-8  px-7 mx-6 pl-lg-9 pr-md-8 pr-lg-10 gr-hover-shadow-1 differ-md-sm"
            style={{ backgroundColor: "#ff565b" }}
          >
            <h4
              className="pre-title gr-text-12   text-uppercase mb-7"
              style={{
                textAlign: "center",
                color: "#ffffff",
                fontSize: "2.1rem",
                fontWeight: "700",
              }}
            >
              Made in Egypt for Egypt
            </h4>{" "}
            <p className="gr-text-9" style={{ color: "#fff" }}>
              Created by Egyptian engineers for the Egyptian market.
            </p>
            <br />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default EzloanDiff;
