import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Button } from "react-bootstrap";
import TypeWriter from "typewriter-effect";
import landing from "../../assets/image/svg/ezloanlanding.png";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div
        className="hero-area position-relative  pt-29 pt-lg-33  pb-lg-35"
        style={{ backgroundColor: "#fcfdfe" }}
      >
        <Container className="landing-large">
          <Row className="justify-content-center  align-items-center">
            <Col
              lg="6"
              md="6"
              className="order-lg-1 mt-9 mt-lg-0 "
              data-aos="fade-right"
              data-aos-duration="500"
              data-aos-once="true"
            >
              <div
                className="hero-content dark-mode-texts landing-text"
                style={{ width: "100%" }}
              >
                <h1
                  className="gr-text-2 mb-9"
                  style={{
                    color: "rgb(74, 99, 165)",
                    fontWeight: "700",
                    minWidth: "15vw",
                  }}
                >
                  <TypeWriter
                    onInit={(typewriter) => {
                      typewriter
                        .changeDelay(80)
                        .typeString("Loan marketplace for the banked")
                        .deleteChars(6)
                        .typeString("unbanked.")

                        .start();
                    }}
                  />
                </h1>

                <p
                  className="gr-text-9 pr-5 pl-0 pr-xl-11 py-10"
                  style={{
                    textAlign: "left",
                  }}
                >
                  EZloan by Genify enables financing for banked, underbanked and
                  unbanked individuals assessed via alternative data sources and
                  meeting lender risk requirements.
                </p>
                <div className="hero-btn pb-10">
                  <Link to="/contact-us#contactform">
                    <Button
                      className="gr-hover-y"
                      style={{ backgroundColor: "#ff565b", border: "0" }}
                    >
                      Request a Demo
                    </Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg="6" md="6" className="order-lg-2 order-sm-2 order-xs-2  ">
              <div
                className="test-landing "
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
                width="100%"
              >
                <img
                  className=""
                  src={landing}
                  alt="ezloan landing"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;
