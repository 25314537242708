import React from "react";

import { Container } from "react-bootstrap";
import LendingMarketplace from "../../components/EzloanFeatures/LendingMarketplace";
import Caas from "../../components/EzloanFeatures/Caas";

const HomeFeatures = () => {
  return (
    <Container className="landing-large">
      <LendingMarketplace />

      <Caas />
    </Container>
  );
};

export default HomeFeatures;
